@import url('https://fonts.googleapis.com/css2?family=Assistant:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Hebrew:wght@100..900&display=swap" rel="stylesheet');
html,body{direction: rtl;overflow-x: hidden;font-family: 'Assistant', sans-serif;}

p{
  font-size: 16px;
  font-weight: 500 !important;
  margin: 0px; padding: 0px;
}
h4{
  font-size: 24px;
  font-weight: 700;
  margin: 0px;
}
h3{
  font-size: 21px; margin-top: 5px;
}

h6{
  padding: 0px; margin: 0px;
  font-size: 20px;
  font-weight: 600 !important;
  color: #01A7C2;
}

.size200{width: 200px;}
.text-bg-info{color: #ffffff !important;}

.badge{padding: 10px;}

.custom-container {
  padding-left: 0; /* Remove left padding */
  padding-right: 0; /* Remove right padding */
}
.avatar-container{
  text-align: center;
  margin: 20px 0;
  background-color: #222222;
}

a.sidemenu_link{
  display: block;
  font-size: 17px;
  padding: 10px 20px;
  color: #ffffff;
  text-decoration: none;
  font-weight: 400;
}
a.sidemenu_link:hover{
  background-color: #ffcc00;
  color: #000000;
}
a.sidemenu_link_active{
  display: block;
  font-size: 17px;
  padding: 10px 20px;
  background-color: #ffcc00;
  color: #000000;
  text-decoration: none;
  font-weight: 400;
}



.modal-header .btn-close{
  margin: 0px;
}

p.status{
  font-size: 20px !important; font-weight: 600 !important;
  letter-spacing: -.5px; margin: 0px !important;
}



.heading-container{
  background-color: #ffcc00;
  padding: 14px;
}

.heading-container-filter{
  background-color: #f1f0f0;
  padding: 14px;
}


.heading-btn-container{
  text-align: left;
}
.btn-success{
  background-color: #00BD9D;
}
.btn{font-weight: 600; font-size: 17px;}
.navbar-nav .dropdown-menu{
  text-align: right;
  padding: 10px;
}
.login-container{
  text-align: center;
  background-color: #ffffff;
  border-radius: 20px;
  padding: 20px 40px;
}
.login-logo{
  width: 80px;
  margin-bottom: 20px;
}
.authSetting{
  background-color: #ebebeb;
  height: 100vh;
}

.form-control{
  padding: 5px;
  font-size: 17px;
  margin-bottom: 6px;
  appearance: auto;
}

.form-control-auth{
  padding: 10px;
  font-size: 17px;
  margin-bottom: 12px;
}

.btn100w{
  width: 100%;
}

p.credit{
  font-size: 13px;
  text-align: center;
  margin-top: 12px;
}

p.profile-name{
  color:#ffcc00;
  font-size: 17px;
  font-weight: 600 !important;
}
p.profile-email{
  color:#ffffff;
  font-size: 14px;
  font-weight: 400 !important;
  margin-top: -5px;
}
.rounded{
  width: 80px;
}

h5{
  margin-top: 6px; margin-left:12px; padding: 0px;
}

/* COMPANY COMPONENTS */
.row-box{background-color: #eeeded; border-radius: 16px; padding: 12px; margin-bottom: 12px;}
.row-logo{width: 60px;}
p.company-name{font-weight: 600 !important; font-size: 20px; color: #006989; text-align: right;}
p.company-address{font-weight: 300 !important; direction: rtl; font-size: 16px; color: #000000; text-align: right;}
.company-box{text-align: center; padding-top: 16px;}
.img-thumbnail{width: 200px;}
.sitesmall-row{margin-bottom: 12px; border-bottom: 1px solod #ccc;}
p.site-name{font-weight: 600 !important; font-size: 17px; color: #007090;}
p.emp-name{font-weight: 400 !important; font-size: 17px; color: #007090;}

.rowshadowbox{
  background-color: #f3f2f2;
  border-radius: 12px;
  box-shadow: 0px 5px 5px -1px rgba(0,0,0,0.26);
  -webkit-box-shadow: 0px 5px 5px -1px rgba(0,0,0,0.15);
  -moz-box-shadow: 0px 5px 5px -1px rgba(0,0,0,0.15);
  padding: 14px;
  margin-bottom: 20px;
}

.rowshadowboxExp{
  border-radius: 12px;
  padding: 14px;
  margin-bottom: 20px;
}



p.stext18{font-weight: 600 !important; font-size: 18px; color: #000000; text-align: right;}
.subcat-container{width:100%; border-radius:6px; background-color: #dde9f4; padding: 10px; margin-bottom: 12px;}


p.tooltip-text{font-weight: 400 !important; font-size: 15px !important; color: #000000; text-align: right;}

.db-heading{
  background-color: #01A7C2;
  padding: 8px;
  border-radius: 12px;
  margin-bottom: 8px;
}
p.db-heading-title{
  color: #fff;
  font-size: 16px;
}
p.log-title{font-size: 16px; margin: 0px !important; padding: 0px !important;}
p.log-description{font-size: 14px; line-height: 15px; font-weight: 400 !important; margin: 0px !important; padding: 0px !important;}
.logrow{
  border-bottom: 1px solid #ccc;
  margin-top: 12px; padding-bottom: 12px;}
p.log-date{font-size: 12px; font-style: italic;}
.logs-scroll-box{height: 400px; overflow-x: hidden; padding-left: 30px;}
.add-machine-box{background-color: #eeeded; padding: 20px; border-radius: 20px;}

.circle{
  margin-top: 20px;
  width:160px; height:160;
  overflow: hidden;
  border-radius: 50%;
  text-align: center;
}
.circle-img{
  width: 160px;
}
.role-box{
  margin-top: 12px;
  border-radius: 12px;
  background-color: #ededed;
  padding: 20px;
}

.card{
  background-color: #edf3f7;
  border:1px solid  #acd4da;
}
.employees{
 background-color: #4179ab; 
}
.committees{
  background-color: #34176e; 
 }

 .findings{
  background-color: #ff6600; 
 }
 .reports{
  background-color: #122c50; 
 }
 .machines{
  background-color: #9b0952; 
 }
 .trainings{
  background-color: #096e36; 
 }

p.db-box-title{
  padding: 0px; margin: 0px;
  font-size: 17px;
}

.planCounter{
  background-color: #e9f4f6;
  padding: 10px;
  border-radius: 10px;
}

p.companyInfo{
  padding: 0px; margin: 0px;
  font-size: 17px; line-height: 20px;
}
.custom-table td, .custom-table th {
  padding: 5px 10px;
}
.offscreen {
  position: absolute;
  left: -9999px;
  top: auto;
  width: 100%;
}



.custom-modal {
  width: 90%;
  max-width: none; /* This will override the default max-width */
}


p.db-box-number{
  color: #0e6da8;
  font-size: 60px;
  font-weight: 400 !important;
  padding: 0px; margin: -10px 0 0 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-color: #aadbeb; /* Change black to any color you like */
}

p.db-box-number222{
  color: #0e6da8;
  font-size: 32px;
  font-weight: 400 !important;
  padding: 0px; margin: -10px 0 0 0;
}



p.db-box-number222-license{
  color: #0e6da8;
  font-size: 40px;
  font-weight: 400 !important;
  padding: 0px; margin: -10px 0 0 0;
}


p.log-title{
  color: #006989;
}

.safety-item-row{
  padding: 8px;
  margin-bottom: 12px;
  background-color: #ededed;
  border-radius: 6px;
}
.safety-item-row-on{
  padding: 16px;
  margin-bottom: 12px;
  background-color: #c0e1ff;
  border-radius: 6px;
}



.safetyCategory-header{
  padding: 16px;
  margin-bottom: 12px;
  background-color: #357aa8;
  border-radius: 6px;
}
h3.safetyCategory-title{
  color: #fff;
}

h1.score-title{
  padding: 0px;
  margin: 0px;
  color: #fff;
}
.findrow{
  margin-bottom: 20px;
  padding: 10px;
  background-color: #f6fdff;
  border-radius: 6px;
}

.safetyCategory-item-row{
  padding: 10px;
  margin-bottom: 12px;
  background-color: #ededed;
  border-radius: 6px;
}
.slider-container{
  background-color: #ffffff;
  border-radius: 12px;
  height: 40px;
}
.btn-sm{
  font-size: 14px;
  line-height: 16px;
  margin-top: 4px;
  color: #ffffff;
}


.custom-modal-super-xl {
  max-width: 95%; /* or any size you want */
}



.finding-row-image{
  height: 100%;
}

.smallRow{
  width:100%;
  padding: 10px;
  background-color: #fff;
  margin-bottom: 10px;
}


/* REPORT-ITEM */
.reportRow{
  margin-bottom: 10px;
  padding: 5px;
  border-radius: 12;
  background-color: "#F8FCFC";
}

.notification-dashboard-title{
  padding: 7px;
  background-color: #c8e8ed;
  border-radius: 10px;
  margin: 6px 0px;
}

.mtop{margin-top: 20px;}

h4.log-title{
  display: block;
  padding:10px;
  font-size: 18px;
  font-weight: 600;
  margin: 12px 0 12px 0;
  border-radius: 6px;
  background-color: #369ebe;
  color: #fff;
}
.log-row{
  width: 100%;
  padding:10px;
  background-color: #f8f8f8;
  border-radius: 6px;
  margin-bottom: 10px;
}
p.log-sub-title{
  font-size: 16px;
  font-weight: 600;
}
p.log-desc{
  font-size: 14px;
  font-weight: 600;
}

.next-container{
  border-radius: 6px;
  text-align: center;
  padding: 20px 0 20px 0;
}
p.next-days{
  color: #fff;
  font-family: 'Assistant', sans-serif;
  font-weight: 600 !important;
}
h6.days{
  font-size: 28px;
}



.site-mobile{
  width: 100%;
}
h2.site-subtitle{
  font-family: "Noto Sans Hebrew", sans-serif;
  font-weight: 800 !important;
  font-size: 60px;
  color: #000000;
  margin: 0px; padding: 0px;
}

h2.yellow-title{
  font-family: "Noto Sans Hebrew", sans-serif;
  font-weight: 800 !important;
  font-size: 40px;
  color: #ffcc00;
  margin: 0px; padding: 0px;
}
h2.white-sub{
  font-family: "Noto Sans Hebrew", sans-serif;
  font-weight: 600 !important;
  font-size: 30px;
  color: #ffffff;
  margin: 0px; padding: 0px;
}

h3.feature-title{
  font-family: "Noto Sans Hebrew", sans-serif;
  font-weight: 600 !important;
  font-size: 28px;
  color: #0a4781;
  margin: 16px 0 16px 0; padding: 0px;
}


h3.feature-price{
  font-family: "Noto Sans Hebrew", sans-serif;
  font-weight: 600 !important;
  font-size: 34px;
  color: #0a4781;
  margin: 16px 0 0px 0; padding: 0px;
}

p.feature-content{
  font-family: "Noto Sans Hebrew", sans-serif;
  font-weight: 400 !important;
  font-size: 17px;
  color: #000000;
  margin: 0px; padding: 0px;
}


p.site-content{
  font-family: "Noto Sans Hebrew", sans-serif;
  font-weight: 400 !important;
  font-size: 18px;
  color: #000000;
  margin: 20px 0 0 0; padding: 0px;
}
.paddingTopBottom{
  padding-top:150px;
}
.footer-container{
  background-color: #282626;
  padding: 50px 0;
}
.slogan-container{
  background-color: #282626;
  padding: 40px 0;
  text-align: center;
}

.hero{
  background-image: url('../public//images//hero.png');
  background-repeat: no-repeat;
  background-size: cover;
  height: 700px;
}

.site-btn{
  width: 100%;
  font-family: "Noto Sans Hebrew", sans-serif;
  font-weight: 600 !important;
  font-size: 20px;
  padding: 14px 0;
  margin-top: 20px;
}

.site-btn2{
  width: 100%;
  font-family: "Noto Sans Hebrew", sans-serif;
  font-weight: 600 !important;
  font-size: 18px;
  padding: 5px 0;
}

.nav-link{
  font-family: "Noto Sans Hebrew", sans-serif;
  font-size: 18px;
}
.btnwrap{
  border: 1px solid #ffcc00;
  border-radius: 12px;
  padding: 0px 20px;
  background-color: #ffcc00;
}

.plan-container{
  border-radius: 12px;
  background-color: #f8f9fa;
  padding: 20px 40px;
  margin-top: 20px;
}

p.listp{
  font-family: "Noto Sans Hebrew", sans-serif;
  font-size: 19px;
  margin: 6px 0px 0px 0px;
  border-bottom: 1px dotted #0e6da8;
  padding: 10px 0 16px 0;
}
p.form-text{
  color: #fff;
  font-family: "Noto Sans Hebrew", sans-serif;
  font-size: 19px; padding: 0px; margin: 0px;
}
p.address{
  color: #fff;
  font-family: "Noto Sans Hebrew", sans-serif;
  font-size: 14px; padding: 0px; margin: 0px;
}
a.foolinks{
  display: block;
  color:#fff;
  font-family: "Noto Sans Hebrew", sans-serif;
  font-size: 16px;
  padding: 8px 0;
  text-decoration: none;
}
a.foolinks:hover{
  color:#ffcc00;
}

.tcol{
  border: 1px solid #222;
  padding: 14px; margin: 0px;
}